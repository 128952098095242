var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var FX,Ona,Pna,Qna,IX,Sna,Tna,Una,Vna,KX,LX,MX,OX,Wna,PX,QX,Xna,Yna,Zna,RX,Rna,SX,TX,$na,VX;FX=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.Xe(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
$CLJS.GX=function(a,b){return function(){function c(m,t,u){return new $CLJS.P(null,2,5,$CLJS.Q,[a.j?a.j(m,t,u):a.call(null,m,t,u),b.j?b.j(m,t,u):b.call(null,m,t,u)],null)}function d(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[a.g?a.g(m,t):a.call(null,m,t),b.g?b.g(m,t):b.call(null,m,t)],null)}function e(m){return new $CLJS.P(null,2,5,$CLJS.Q,[a.h?a.h(m):a.call(null,m),b.h?b.h(m):b.call(null,m)],null)}function f(){return new $CLJS.P(null,2,5,$CLJS.Q,[a.o?a.o():a.call(null),b.o?b.o():b.call(null)],null)}
var k=null,l=function(){function m(u,v,x,A){var C=null;if(3<arguments.length){C=0;for(var G=Array(arguments.length-3);C<G.length;)G[C]=arguments[C+3],++C;C=new $CLJS.w(G,0,null)}return t.call(this,u,v,x,C)}function t(u,v,x,A){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ne.M(a,u,v,x,A),$CLJS.Ne.M(b,u,v,x,A)],null)}m.A=3;m.B=function(u){var v=$CLJS.z(u);u=$CLJS.B(u);var x=$CLJS.z(u);u=$CLJS.B(u);var A=$CLJS.z(u);u=$CLJS.Hc(u);return t(v,x,A,u)};m.l=t;return m}();k=function(m,t,u,v){switch(arguments.length){case 0:return f.call(this);
case 1:return e.call(this,m);case 2:return d.call(this,m,t);case 3:return c.call(this,m,t,u);default:var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.w(A,0,null)}return l.l(m,t,u,x)}throw Error("Invalid arity: "+arguments.length);};k.A=3;k.B=l.B;k.o=f;k.h=e;k.g=d;k.j=c;k.l=l.l;return k}()};$CLJS.HX=function(a,b){return $CLJS.SK(a)(b,$CLJS.xf,$CLJS.xf)};
Ona=function(a,b,c){return $CLJS.cb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.ZE,b),$CLJS.Wr(2,2,c))};Pna=function(a,b){var c=$CLJS.El(a,$CLJS.HP);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Cb(c);b=$CLJS.gs.g?$CLJS.gs.g(c,b):$CLJS.gs.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.HP,b)}return a};Qna=function(a){return $CLJS.bf.g($CLJS.NU(a),$CLJS.OU(a))};
IX=function(a,b,c){var d=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.Dr,null,$CLJS.Sk,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.st(2,c):$CLJS.st(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?FX(2,c):FX(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.xW.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.ud(k)?$CLJS.Wf.j($CLJS.xf,$CLJS.Yj.h($CLJS.Zk),l):$CLJS.sd(k)?$CLJS.Jz($CLJS.Sa,l):l}):$CLJS.Qj.g(a,f)};
Sna=function(a){return $CLJS.cb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return IX(b,Rna,d)},a,Qna(a))};
Tna=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.qK.h(a),b);a:{var d=c,e=$CLJS.ND;$CLJS.ND=!0;try{for(var f=$CLJS.xf;;){var k=$CLJS.z($CLJS.cf.g($CLJS.GX($CLJS.Oi,$CLJS.Nk),$CLJS.ff($CLJS.Xj.j(JX,$CLJS.z,$CLJS.Nk),$CLJS.Hr.h($CLJS.HX($CLJS.mV,d)))));if($CLJS.n(k)){var l=k,m=$CLJS.I(l,0,null),t=$CLJS.I(l,1,null),u=IX(d,m,t);l=$CLJS.xh;var v=l.l,x=m;var A=$CLJS.n(x)?x:$CLJS.XK($CLJS.HX($CLJS.mV,d));var C=v.call(l,$CLJS.H([A]));console.warn("Clean: Removing bad clause due to error!",t,C,$CLJS.wV($CLJS.z($CLJS.Zs(d,
u))));if($CLJS.E.g(u,d)){var G=d;break a}l=u;var K=$CLJS.Yd.g(f,new $CLJS.P(null,2,5,$CLJS.Q,[m,t],null));d=l;f=K}else{G=d;break a}}}finally{$CLJS.ND=e}G=void 0}e=Sna(G);if($CLJS.E.g(c,e)){if($CLJS.E.g(b,$CLJS.D($CLJS.qK.h(a))-1))return a;b+=1}else a=$CLJS.ak.M(a,$CLJS.qK,$CLJS.R,b,e)}};Una=function(a){var b=$CLJS.JV();return $CLJS.Zj.g(function(c){return $CLJS.E.g($CLJS.VD.h(c),"__join")?$CLJS.ak.j(c,$CLJS.VD,b):c},a)};
Vna=function(a){return"string"===typeof $CLJS.gO.h(a)?$CLJS.Qj.g($CLJS.R.j(a,$CLJS.PU,$CLJS.HV($CLJS.gO.h(a))),$CLJS.gO):a};KX=function(){return $CLJS.Yj.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.he(b))?(b=$CLJS.de(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};LX=function(a){return $CLJS.Wf.j($CLJS.N,KX(),a)};
MX=function(a){return $CLJS.Be($CLJS.Wf.j($CLJS.N,$CLJS.Xj.g(KX(),$CLJS.Yj.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.li)})),a))};
OX=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);var d=$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.cf.h($CLJS.NX),c);a=$CLJS.E.g(a,$CLJS.SE)&&$CLJS.B(c)?$CLJS.Yd.g($CLJS.nd(d),new $CLJS.h(null,1,[$CLJS.Oh,$CLJS.md(d)],null)):d;b=$CLJS.Be(MX(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wL,a,b],null):a};Wna=function(a){return $CLJS.Wf.j($CLJS.xf,$CLJS.Xj.g($CLJS.cf.h(function(b){return $CLJS.Ez(b,$CLJS.EA)}),$CLJS.cf.h($CLJS.NX)),$CLJS.DV.h(a))};
PX=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.qK);a=$CLJS.z($CLJS.cb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.NX.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.JP,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.TN,Wna(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.pU.h(c)],null)},null,a));return $CLJS.n($CLJS.kP.h(a))?$CLJS.AU(a,new $CLJS.h(null,1,[$CLJS.kP,$CLJS.FE],null)):a};
QX=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.UV(a,b,$CLJS.Xj.g($CLJS.NX,$CLJS.z)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.UV(d,b,function(e){return $CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.lr],null),$CLJS.cf.h($CLJS.NX),e)}):d;return $CLJS.AU(a,$CLJS.Ae([b,c]))};Xna=function(a){var b=$CLJS.PU.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Qj.g(a,$CLJS.PU),$CLJS.gO,["card__",$CLJS.p.h(b)].join("")):a};
Yna=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);Zna=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);RX=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Rna=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);SX=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
TX=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.UX=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);$na=new $CLJS.M(null,"m","m",1632677161);VX=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var JX,aoa,boa,coa,doa,eoa,foa,goa,hoa,ioa,joa;$CLJS.WX=$CLJS.N;$CLJS.XX=$CLJS.N;JX=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.jV,null,$CLJS.RD,null,$CLJS.FM,null,$CLJS.aP,null,$CLJS.YE,null,$CLJS.PQ,null,$CLJS.ZE,null],null),null);aoa=$CLJS.Se($CLJS.N);boa=$CLJS.Se($CLJS.N);coa=$CLJS.Se($CLJS.N);doa=$CLJS.Se($CLJS.N);eoa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Mi,$CLJS.fB],null),$CLJS.Mi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.YX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.WA,eoa,aoa,boa,coa,doa);$CLJS.YX.m(null,$CLJS.Oh,function(a){if($CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M){var b=$CLJS.md(a);var c=$CLJS.sd(b);a=c?$CLJS.nd(a):a;var d=$CLJS.y(a);a=$CLJS.z(d);d=$CLJS.B(d);b=$CLJS.qV($CLJS.Wf.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.cf.h($CLJS.YX),d))}else b=a;return b});$CLJS.YX.m(null,$CLJS.iV,function(a){return a});
$CLJS.YX.m(null,$CLJS.WU,function(a){var b=$CLJS.YX.h($CLJS.ZE.h(a)),c=$CLJS.Be($CLJS.Zj.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.AV($CLJS.YX.h(t),u)},$CLJS.aP.h(a))),d=$CLJS.Wf.j($CLJS.N,$CLJS.Fl(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.xD);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.Wf.j($CLJS.N,$CLJS.Fl(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.xD);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.XX,k=$CLJS.WX;$CLJS.XX=d;$CLJS.WX=e;try{var l=Ona(Vna(a),b,$CLJS.H([$CLJS.aP,c])),m=$CLJS.cb(function(t,u){return $CLJS.Ra($CLJS.J.g(t,u))?t:$CLJS.ak.j(t,u,$CLJS.YX)},l,$CLJS.Rj.l(JX,$CLJS.ZE,$CLJS.H([$CLJS.aP])));return $CLJS.n($CLJS.FM.h(m))?$CLJS.ak.j(m,$CLJS.FM,Una):m}finally{$CLJS.WX=k,$CLJS.XX=f}});$CLJS.YX.m(null,$CLJS.cV,function(a){return Pna(a,function(b){return $CLJS.UV(b,$CLJS.CE,$CLJS.YX)})});
$CLJS.YX.m(null,$CLJS.lK,function(a){a=$CLJS.ak.j($CLJS.ak.j(a,$CLJS.tK,$CLJS.YX),$CLJS.qK,$CLJS.YX);var b=$CLJS.n($CLJS.RD.h(a))?$CLJS.ak.j(a,$CLJS.RD,function(c){return $CLJS.kl(c)?$CLJS.Zj.g($CLJS.YX,c):$CLJS.mh.h(c)}):a;return $CLJS.Ra($CLJS.VD.h(a))?$CLJS.R.j(b,$CLJS.VD,"__join"):b});$CLJS.YX.m(null,$CLJS.nA,function(a){return $CLJS.Zj.g($CLJS.YX,a)});
$CLJS.YX.m(null,$CLJS.pA,function(a){return $CLJS.n($CLJS.Oi.h(a))?Tna($CLJS.R.j($CLJS.ak.j($CLJS.EV(a),$CLJS.qK,function(b){return $CLJS.Zj.g($CLJS.YX,b)}),$CLJS.UX,!0)):$CLJS.gs(a,$CLJS.YX)});$CLJS.YX.m(null,$CLJS.jF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.sd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.qV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,a,b],null))});
$CLJS.YX.m(null,$CLJS.aj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.AU(a,new $CLJS.h(null,3,[$CLJS.bC,$CLJS.tA,$CLJS.FN,$CLJS.mj,$CLJS.XQ,$CLJS.sD],null));var c=$CLJS.R.j;var d=$CLJS.li.h(a);$CLJS.n(d)||(d=$CLJS.tA.h(a),d=$CLJS.n(d)?d:$CLJS.LD(b));a=c.call($CLJS.R,a,$CLJS.li,d);return $CLJS.qV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aj,a,b],null))});
$CLJS.YX.m(null,$CLJS.SE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.Oh.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SE,$CLJS.Qj.g(c,$CLJS.Oh),$CLJS.Zj.g($CLJS.YX,b)],null);b=$CLJS.qV(b);return null!=a?$CLJS.Yd.g(b,$CLJS.YX.h(a)):b});$CLJS.YX.m(null,$CLJS.qA,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.qV(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.YX.m(null,$CLJS.ZE,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null),e=$CLJS.Q;var f=$CLJS.J.g($CLJS.XX,c);if(!$CLJS.n(f))throw $CLJS.Uh(["Error converting :aggregation reference: no aggregation at index ",$CLJS.p.h(c)].join(""),new $CLJS.h(null,1,[$CLJS.$Q,a],null));return $CLJS.qV(new $CLJS.P(null,3,5,e,[b,d,f],null))});
$CLJS.YX.m(null,$CLJS.wL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.YX.h(b);var c=$CLJS.y(b);b=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.pk.l($CLJS.H([c,a]))],null),d)});foa=$CLJS.Se($CLJS.N);goa=$CLJS.Se($CLJS.N);hoa=$CLJS.Se($CLJS.N);ioa=$CLJS.Se($CLJS.N);joa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Mi,$CLJS.fB],null),$CLJS.Mi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.NX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.WA,joa,foa,goa,hoa,ioa);
$CLJS.NX.m(null,$CLJS.Oh,function(a){if($CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);if($CLJS.sd(a)){c=$CLJS.Wf.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.bf.g;d=$CLJS.cf.g($CLJS.NX,d);a=MX(a);a=c.call($CLJS.Wf,b,e.call($CLJS.bf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.cf.g($CLJS.NX,$CLJS.ae(a,d)))}else a=$CLJS.sd(a)?$CLJS.gs(LX(a),$CLJS.NX):
a;return a});for(var ZX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[VX,TX],null)),$X=null,aY=0,bY=0;;)if(bY<aY){var koa=$X.X(null,bY);$CLJS.pE(koa,SX);bY+=1}else{var cY=$CLJS.y(ZX);if(cY){var dY=cY;if($CLJS.vd(dY)){var eY=$CLJS.ic(dY),loa=$CLJS.jc(dY),moa=eY,noa=$CLJS.D(eY);ZX=loa;$X=moa;aY=noa}else{var ooa=$CLJS.z(dY);$CLJS.pE(ooa,SX);ZX=$CLJS.B(dY);$X=null;aY=0}bY=0}else break}
for(var fY=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Rw,$CLJS.dF,$CLJS.sF,$CLJS.OE,$CLJS.vj,$CLJS.wF,$CLJS.oi,$CLJS.VE,$CLJS.qF,$CLJS.xF,$CLJS.vF,$CLJS.CF],null)),gY=null,hY=0,iY=0;;)if(iY<hY){var poa=gY.X(null,iY);$CLJS.pE(poa,VX);iY+=1}else{var jY=$CLJS.y(fY);if(jY){var kY=jY;if($CLJS.vd(kY)){var lY=$CLJS.ic(kY),qoa=$CLJS.jc(kY),roa=lY,soa=$CLJS.D(lY);fY=qoa;gY=roa;hY=soa}else{var toa=$CLJS.z(kY);$CLJS.pE(toa,VX);fY=$CLJS.B(kY);gY=null;hY=0}iY=0}else break}
for(var mY=$CLJS.y($CLJS.tf([$CLJS.Kq,$CLJS.Zr,$CLJS.Lq,$CLJS.DF,$CLJS.SE,$CLJS.mF,$CLJS.JE,$CLJS.FF,$CLJS.AE,$CLJS.XE,$CLJS.EE,$CLJS.hF,$CLJS.oF,$CLJS.GE,$CLJS.YA,$CLJS.ZA,$CLJS.Fx,$CLJS.UH,$CLJS.ZH,$CLJS.OH,$CLJS.$A,$CLJS.TH,$CLJS.MH,$CLJS.bI,$CLJS.gI,$CLJS.dI,$CLJS.SH,$CLJS.iI,$CLJS.aB,$CLJS.dB,$CLJS.IE,$CLJS.BE,$CLJS.GF,$CLJS.UG,$CLJS.lF,$CLJS.Pw,$CLJS.yF,$CLJS.AF,$CLJS.HF,$CLJS.js,$CLJS.ps],!0)),nY=null,oY=0,pY=0;;)if(pY<oY){var uoa=nY.X(null,pY);$CLJS.pE(uoa,TX);pY+=1}else{var qY=$CLJS.y(mY);
if(qY){var rY=qY;if($CLJS.vd(rY)){var sY=$CLJS.ic(rY),voa=$CLJS.jc(rY),woa=sY,xoa=$CLJS.D(sY);mY=voa;nY=woa;oY=xoa}else{var yoa=$CLJS.z(rY);$CLJS.pE(yoa,TX);mY=$CLJS.B(rY);nY=null;oY=0}pY=0}else break}$CLJS.NX.m(null,SX,function(a){return OX(a)});$CLJS.NX.m(null,$CLJS.pA,function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.Xj.g(KX(),$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.NX.h(b)],null)})),a)});
$CLJS.NX.m(null,$CLJS.ZE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.sd(b))try{var d=MX(b);b=$CLJS.Q;var e=$CLJS.WX,f=$CLJS.J.j(e,c,RX);if($CLJS.E.g(f,RX))throw $CLJS.Uh(["Unable to find key ",$CLJS.xh.l($CLJS.H([c]))," in map."].join(""),new $CLJS.h(null,2,[$na,e,$CLJS.Vv,c],null));var k=new $CLJS.P(null,2,5,b,[$CLJS.ZE,f],null);return $CLJS.y(d)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wL,k,d],null):k}catch(l){throw c=l,d=$CLJS.uU(c),d=$CLJS.ha.g?$CLJS.ha.g("Error converting aggregation reference to pMBQL: %s",
d):$CLJS.ha.call(null,"Error converting aggregation reference to pMBQL: %s",d),$CLJS.Th(d,new $CLJS.h(null,1,[$CLJS.W,a],null),c);}else return a});$CLJS.NX.m(null,$CLJS.nA,function(a){return $CLJS.Zj.g($CLJS.NX,a)});
$CLJS.NX.m(null,$CLJS.jF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.sd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,$CLJS.NX.h(a),MX(b)],null)});
$CLJS.NX.m(null,$CLJS.aj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=MX($CLJS.AU(b,new $CLJS.h(null,3,[$CLJS.tA,$CLJS.bC,$CLJS.mj,$CLJS.FN,$CLJS.sD,$CLJS.XQ],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aj,a,b],null)});
$CLJS.NX.m(null,$CLJS.lK,function(a){var b=LX(a);a=0==$CLJS.VD.h(a).lastIndexOf("__join",0)?$CLJS.Qj.g(b,$CLJS.VD):b;return $CLJS.pk.l($CLJS.H([$CLJS.gs($CLJS.Qj.l(a,$CLJS.qK,$CLJS.H([$CLJS.tK])),$CLJS.NX),QX($CLJS.Dl(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.tK],null)),$CLJS.tK,$CLJS.RQ),PX(a)]))});
$CLJS.NX.m(null,$CLJS.WU,function(a){var b=$CLJS.ZE.h(a),c=$CLJS.Wf.j($CLJS.N,$CLJS.Fl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.xD);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.Fl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.xD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.XX,e=$CLJS.WX;$CLJS.XX=c;$CLJS.WX=b;try{return $CLJS.cb(function(f,k){return $CLJS.UV(f,
k,$CLJS.NX)},QX($CLJS.UV($CLJS.UV(Xna(LX(a)),$CLJS.ZE,function(f){return $CLJS.Zj.g(OX,f)}),$CLJS.aP,function(f){return $CLJS.Wf.g($CLJS.N,function(){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.vd(t)){var u=$CLJS.ic(t),v=$CLJS.D(u),x=$CLJS.le(v);a:for(var A=0;;)if(A<v){var C=$CLJS.hd(u,A),G=$CLJS.NX.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zV(C),$CLJS.E.g($CLJS.aj,$CLJS.z(G))?$CLJS.dd(G):G],null);x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.oe($CLJS.qe(x),
m($CLJS.jc(t))):$CLJS.oe($CLJS.qe(x),null)}x=$CLJS.z(t);u=$CLJS.NX.h(x);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zV(x),$CLJS.E.g($CLJS.aj,$CLJS.z(u))?$CLJS.dd(u):u],null),m($CLJS.Hc(t)))}return null}},null,null)}(f)}())}),$CLJS.jV,$CLJS.NE),$CLJS.Rj.l(JX,$CLJS.ZE,$CLJS.H([$CLJS.jV,$CLJS.aP])))}finally{$CLJS.WX=e,$CLJS.XX=d}});$CLJS.NX.m(null,$CLJS.cV,function(a){return $CLJS.gs(LX(a),$CLJS.NX)});
$CLJS.NX.m(null,$CLJS.iV,function(a){try{var b=LX(a),c=$CLJS.wM.h(b),d=PX(b),e=$CLJS.E.g($CLJS.Vs.h($CLJS.ed($CLJS.qK.h(a))),$CLJS.cV)?$CLJS.kP:$CLJS.FE;return $CLJS.pk.l($CLJS.H([$CLJS.Qj.l(b,$CLJS.qK,$CLJS.H([$CLJS.wM,$CLJS.UX])),function(){var k=$CLJS.Ae([$CLJS.Oi,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.wM,c):k}()]))}catch(k){var f=k;throw $CLJS.Th(function(){var l=$CLJS.uU(f);return $CLJS.ha.g?$CLJS.ha.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ha.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.FE,a],null),f);}});
$CLJS.tY=function(){function a(d,e,f){f=$CLJS.EX(null,$CLJS.Ly(f,$CLJS.H([$CLJS.hi,!0])));var k=$CLJS.GV(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.ZE);k=$CLJS.Wf.j($CLJS.N,$CLJS.Fl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.xD);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.Wf.j($CLJS.N,$CLJS.Fl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.xD);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.XX,t=$CLJS.WX;$CLJS.XX=k;$CLJS.WX=l;try{try{return $CLJS.YX.h(f)}catch(v){var u=v;throw $CLJS.Th(function(){var x=$CLJS.uU(u);return $CLJS.ha.g?$CLJS.ha.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.ha.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.FE,d,$CLJS.GK,e,Zna,f,Yna,$CLJS.XX],null),u);}}finally{$CLJS.WX=t,$CLJS.XX=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();