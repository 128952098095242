var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.types.constants.js");require("./metabase.lib.util.js");require("./metabase.types.js");
'use strict';var GZ,NZ;$CLJS.DZ=function(a){return $CLJS.CZ($CLJS.yZ,a)};$CLJS.EZ=function(a){return $CLJS.CZ($CLJS.nZ,a)};$CLJS.FZ=function(a){return $CLJS.CZ($CLJS.kZ,a)};GZ=function(a){return $CLJS.CZ($CLJS.mZ,a)};$CLJS.HZ=function(a){return $CLJS.CZ($CLJS.sj,a)};$CLJS.IZ=function(a){return $CLJS.CZ($CLJS.qZ,a)};$CLJS.JZ=function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.BC)};$CLJS.KZ=function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.Wh)};$CLJS.LZ=function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.ai)};
$CLJS.MZ=function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.qi)};NZ=function(a){return $CLJS.Dz($CLJS.li.h(a),$CLJS.ij)};$CLJS.OZ=function(a){var b=NZ(a);return b?(a=$CLJS.mj.h(a),null==a||$CLJS.Dz(a,$CLJS.ij)):b};$CLJS.PZ=function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.pi)};$CLJS.QZ=function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.lC)};$CLJS.RZ=function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.MC)};$CLJS.SZ=function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.GC)};
$CLJS.TZ=function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.Xi)};$CLJS.UZ=function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.VB)};$CLJS.VZ=function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.HC)};$CLJS.WZ=function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.PB)};$CLJS.XZ=function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.Wh)||$CLJS.Dz($CLJS.mj.h(a),$CLJS.ai)};$CLJS.CZ=function CZ(a,b){a=$CLJS.BZ.h?$CLJS.BZ.h(a):$CLJS.BZ.call(null,a);var d=$CLJS.sd(b)&&$CLJS.Ra($CLJS.li.h(b))?$CLJS.R.j(b,$CLJS.li,$CLJS.tA.h(b)):b;return null==d?!1:$CLJS.n($CLJS.Me(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=function(){var l=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.mj,null,$CLJS.li,null],null),null);return l.h?l.h(f):l.call(null,f)}();return $CLJS.n(k)?$CLJS.Me(function(l){return $CLJS.Dz(f.h?f.h(d):f.call(null,d),l)},e):k},a))?!0:$CLJS.n($CLJS.Me(function(e){return CZ.g?
CZ.g(e,d):CZ.call(null,e,d)},$CLJS.gZ.h(a)))?!1:$CLJS.n($CLJS.Me(function(e){return CZ.g?CZ.g(e,d):CZ.call(null,e,d)},$CLJS.tZ.h(a)))?!0:!1};
module.exports={URL_QMARK_:function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.MB)},string_like_QMARK_:GZ,creation_time_QMARK_:function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.NB)},any_QMARK_:function(){return!0},description_QMARK_:$CLJS.JZ,foreign_key_QMARK_:$CLJS.KZ,metric_QMARK_:function(a){return $CLJS.Wj.g($CLJS.KJ.h(a),$CLJS.aK)&&$CLJS.HZ(a)},address_QMARK_:$CLJS.PZ,isa_QMARK_:function(a,b){var c=$CLJS.O(a);a=$CLJS.J.g(c,$CLJS.li);var d=$CLJS.J.g(c,$CLJS.tA);c=$CLJS.J.g(c,$CLJS.mj);return $CLJS.Dz($CLJS.n(a)?
a:d,b)||$CLJS.Dz(c,b)},boolean_QMARK_:function(a){return $CLJS.CZ($CLJS.sZ,a)},category_QMARK_:$CLJS.IZ,email_QMARK_:function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.gC)},creation_date_QMARK_:function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.ZB)},numeric_QMARK_:$CLJS.EZ,zip_code_QMARK_:function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.LB)},field_type_QMARK_:$CLJS.CZ,longitude_QMARK_:$CLJS.VZ,time_QMARK_:function(a){return $CLJS.Dz($CLJS.li.h(a),$CLJS.Mk)},id_QMARK_:$CLJS.XZ,temporal_QMARK_:$CLJS.DZ,state_QMARK_:$CLJS.RZ,
entity_name_QMARK_:function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.Ni)},city_QMARK_:$CLJS.QZ,string_QMARK_:$CLJS.FZ,title_QMARK_:function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.CC)},field_type:function(a){return $CLJS.pG(function(b){return $CLJS.CZ(b,a)},new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.yZ,$CLJS.oZ,$CLJS.xZ,$CLJS.rZ,$CLJS.vZ,$CLJS.sZ,$CLJS.kZ,$CLJS.mZ,$CLJS.nZ],null))},latitude_QMARK_:$CLJS.UZ,location_QMARK_:function(a){return $CLJS.CZ($CLJS.oZ,a)},primary_key_QMARK_:$CLJS.LZ,dimension_QMARK_:function(a){return $CLJS.n(a)?
$CLJS.Wj.g($CLJS.KJ.h(a),$CLJS.jK)&&!$CLJS.JZ(a):a},creation_timestamp_QMARK_:function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.CB)},image_URL_QMARK_:function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.JC)},coordinate_QMARK_:$CLJS.TZ,json_QMARK_:function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.ii)},avatar_URL_QMARK_:function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.uC)},summable_QMARK_:$CLJS.HZ,scope_QMARK_:function(a){return $CLJS.CZ($CLJS.Di,a)},country_QMARK_:$CLJS.SZ,string_or_string_like_QMARK_:function(a){return $CLJS.FZ(a)||
GZ(a)},integer_QMARK_:function(a){return $CLJS.CZ($CLJS.zZ,a)},structured_QMARK_:$CLJS.MZ,has_latitude_and_longitude_QMARK_:function(a){return $CLJS.Le(function(b){return $CLJS.Me(b,a)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UZ,$CLJS.VZ],null))},comment_QMARK_:$CLJS.WZ,currency_QMARK_:function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.JB)},xml_QMARK_:function(a){return $CLJS.Dz($CLJS.mj.h(a),$CLJS.xj)},numeric_base_type_QMARK_:NZ,primary_key_pred:function(a){return function(b){var c=$CLJS.LZ(b);return $CLJS.n($CLJS.HV(a))?
c:c&&$CLJS.E.g($CLJS.ZJ.h(b),a)}},date_without_time_QMARK_:function(a){return $CLJS.Dz($CLJS.li.h(a),$CLJS.xk)},number_QMARK_:$CLJS.OZ};